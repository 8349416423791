import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ResetPassword from './pages/resetPassword';
import Domain from './pages/domain';
import Welcome from './pages/Welcome';

export default function Router() {
    return (
        <BrowserRouter>
            <Routes>

                <Route path='/:domain' element={<Domain />} />
                <Route path='/' element={<Welcome />} />
                <Route path='/resetPassword' element={<ResetPassword />} />

            </Routes>
        </BrowserRouter>
    )
}