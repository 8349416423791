import { useEffect, useState } from "react";
import playButton from '../assets/images/playButton.png';

export default function MusicSection(props) {

    let { initMusic, goTo } = props;
    const [music, setMusic] = useState();

    useEffect(() => {
        if (initMusic) {
            setMusic(initMusic)
        }
    }, [initMusic])

    return (
        <div className="w-full flex flex-col gap-y-[10px] mt-[15px]">
            {
                music && music.length > 0 && <span className="text-white text-[18px] leading-[22px] p-2">LATEST</span>
            }

            {
                music && music.length >= 4 &&
                <div className="w-full flex flex-row justify-center">
                    <div className="grid grid-cols-4 gap-x-[6px]">
                        {
                            music.slice(0, 4).map((item, key) => (
                                <div className="flex flex-col bg-[#1A1A1A] rounded-[6px]">
                                    <div className="relative">
                                        {
                                            Number(item.type) == 2 ?
                                                <img key={key} src={`${process.env.REACT_APP_STATIC_URL}/${item.image}`} alt="" className="w-[70px] h-[100px] rounded-[6px]" onClick={() => goTo(item.uri)} /> :
                                                <img key={key} src={`${item.image}`} alt="" className="w-[70px] h-[100px] rounded-[6px]" onClick={() => goTo(item.uri)} />
                                        }
                                        <img src={playButton} alt="" className="absolute bottom-[10px] right-[10px] w-[20px] h-[20px]" />
                                    </div>
                                    <div className="flex flex-col px-[6px] py-[6px] gap-y-[5px]">
                                        <span className="text-white text-[15px] leading-[16px]">{item.title.length > 13 ? item.title.slice(0, 13) + '...' : item.title}</span>
                                        <span className="text-white text-[12px] leading-[13px]">{item.artist.length > 13 ? item.artist.slice(0, 13) + '...' : item.artist}</span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            }

            {
                music && music.length == 3 &&
                <div className="w-full flex flex-row justify-center">
                    <div className="grid grid-cols-3 gap-x-[10px]">
                        {
                            music.map((item, key) => (
                                <div className="flex flex-col bg-[#1A1A1A] rounded-[6px]">
                                    <div className="relative">
                                        {
                                            Number(item.type) == 2 ?
                                                <img key={key} src={`${process.env.REACT_APP_STATIC_URL}/${item.image}`} alt="" className="w-[120px] h-[150px] rounded-[6px]" onClick={() => goTo(item.uri)} /> :
                                                <img key={key} src={`${item.image}`} alt="" className="w-[120px] h-[150px] rounded-[6px]" onClick={() => goTo(item.uri)} />
                                        }
                                        <img src={playButton} alt="" className="absolute bottom-[10px] right-[10px] w-[20px] h-[20px]" />
                                    </div>
                                    <div className="flex flex-col px-[6px] py-[6px] gap-y-[5px]">
                                        <span className="text-white text-[15px] leading-[16px]">{item.title.length > 13 ? item.title.slice(0, 13) + '...' : item.title}</span>
                                        <span className="text-white text-[12px] leading-[13px]">{item.artist.length > 13 ? item.artist.slice(0, 13) + '...' : item.artist}</span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>

            }

            {
                music && music.length == 2 &&
                <div className="w-full flex flex-row justify-center">
                    <div className="grid grid-cols-2 gap-x-[15px]">
                        {
                            music.slice(0, 4).map((item, key) => (
                                <div className="flex flex-col bg-[#1A1A1A] rounded-[6px]">
                                    <div className="relative">
                                        {
                                            Number(item.type) == 2 ?
                                                <img key={key} src={`${process.env.REACT_APP_STATIC_URL}/${item.image}`} alt="" className="w-[180px] h-[150px] rounded-[6px]" onClick={() => goTo(item.uri)} /> :
                                                <img key={key} src={`${item.image}`} alt="" className="w-[180px] h-[150px] rounded-[6px]" onClick={() => goTo(item.uri)} />
                                        }
                                        <img src={playButton} alt="" className="absolute bottom-[10px] right-[10px] w-[20px] h-[20px]" />
                                    </div>
                                    <div className="flex flex-col px-[6px] py-[6px] gap-y-[5px]">
                                        <span className="text-white text-[15px] leading-[16px]">{item.title.length > 13 ? item.title.slice(0, 13) + '...' : item.title}</span>
                                        <span className="text-white text-[12px] leading-[13px]">{item.artist.length > 13 ? item.artist.slice(0, 13) + '...' : item.artist}</span>
                                    </div>
                                </div>

                            ))
                        }
                    </div>
                </div>

            }

            {
                music && music.length == 1 &&
                <div className="w-full flex flex-row justify-center">
                    <div className="grid grid-cols-1">
                        {
                            music.slice(0, 4).map((item, key) => (
                                <div className="flex flex-col bg-[#1A1A1A] rounded-[6px]">
                                    <div className="relative">
                                        {
                                            Number(item.type) == 2 ?
                                                <img key={key} src={`${process.env.REACT_APP_STATIC_URL}/${item.image}`} alt="" className="w-[370px] h-[200px] rounded-[6px]" onClick={() => goTo(item.uri)} /> :
                                                <img key={key} src={`${item.image}`} alt="" className="w-[370px] h-[200px] rounded-[6px]" onClick={() => goTo(item.uri)} />
                                        }
                                        <img src={playButton} alt="" className="absolute bottom-[10px] right-[10px] w-[20px] h-[20px]" />
                                    </div>
                                    <div className="flex flex-col px-[6px] py-[6px] gap-y-[5px]">
                                        <span className="text-white text-[15px] leading-[16px]">{item.title.length > 13 ? item.title.slice(0, 13) + '...' : item.title}</span>
                                        <span className="text-white text-[12px] leading-[13px]">{item.artist.length > 13 ? item.artist.slice(0, 13) + '...' : item.artist}</span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            }


        </div>
    )
}