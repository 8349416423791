import { useEffect, useState } from "react";

export default function SocialFeedSection(props) {

    let { initSocialFeed, goTo } = props;
    const [socialFeed, setSocialFeed] = useState();

    useEffect(() => {
        if (initSocialFeed) {
            setSocialFeed(initSocialFeed)
        }
    }, [initSocialFeed])

    return (
        <div className="w-full flex flex-col gap-y-[10px]">

            {
                socialFeed && socialFeed.length > 0 && <span className="text-white text-[18px] leading-[22px]">REELS</span>
            }


            {
                socialFeed && socialFeed.length >= 4 &&
                <div className="w-full flex flex-row justify-center">
                    <div className="grid grid-cols-4 gap-x-[6px]">
                        {
                            socialFeed.slice(0, 4).map((item, key) => (
                                Number(item.type) ?
                                    <img key={key} src={`${process.env.REACT_APP_STATIC_URL}/${item.image}`} alt="" className="w-[70px] h-[100px] rounded-[6px]" onClick={() => goTo(item.uri)} /> :
                                    <img key={key} src={`${item.image}`} alt="" className="w-[70px] h-[100px] rounded-[6px]" onClick={() => goTo(item.uri)} />
                            ))
                        }
                    </div>
                </div>
            }

            {
                socialFeed && socialFeed.length == 3 &&
                <div className="w-full flex flex-row justify-center">
                    <div className="grid grid-cols-3 gap-x-[10px]">
                        {
                            socialFeed.map((item, key) => (
                                Number(item.type) ?
                                    <img key={key} src={`${process.env.REACT_APP_STATIC_URL}/${item.image}`} alt="" className="w-[120px] h-[150px] rounded-[6px]" onClick={() => goTo(item.uri)} /> :
                                    <img key={key} src={`${item.image}`} alt="" className="w-[120px] h-[150px] rounded-[6px]" onClick={() => goTo(item.uri)} />
                            ))
                        }
                    </div>
                </div>

            }

            {
                socialFeed && socialFeed.length == 2 &&
                <div className="w-full flex flex-row justify-center">
                    <div className="grid grid-cols-2 gap-x-[15px]">
                        {
                            socialFeed.slice(0, 4).map((item, key) => (
                                Number(item.type) ?
                                    <img key={key} src={`${process.env.REACT_APP_STATIC_URL}/${item.image}`} alt="" className="w-[180px] h-[150px] rounded-[6px]" onClick={() => goTo(item.uri)} /> :
                                    <img key={key} src={`${item.image}`} alt="" className="w-[180px] h-[150px] rounded-[6px]" onClick={() => goTo(item.uri)} />
                            ))
                        }
                    </div>
                </div>
            }

            {
                socialFeed && socialFeed.length == 1 &&
                <div className="w-full flex flex-row justify-center">
                    <div className="grid grid-cols-1">
                        {
                            socialFeed.slice(0, 4).map((item, key) => (
                                Number(item.type) ?
                                    <img key={key} src={`${process.env.REACT_APP_STATIC_URL}/${item.image}`} alt="" className="w-[370px] h-[200px] rounded-[6px]" onClick={() => goTo(item.uri)} /> :
                                    <img key={key} src={`${item.image}`} alt="" className="w-[370px] h-[200px] rounded-[6px]" onClick={() => goTo(item.uri)} />
                            ))
                        }
                    </div>
                </div>
            }


        </div>
    )
}