import axios from "axios";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import Assets from "../constant";
import EventSection from "../components/EventSection";
import VideoSection from "../components/VideoSection";
import MusicSection from "../components/MusicSection";
import SocialFeedSection from "../components/SocialFeedSection";
import CustomLinkSection from "../components/CustomLinkSection";
import ProductSection from "../components/ProductSection";

import closeButton from '../assets/images/close.png';

const Domain = () => {

    const { domain } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isShow, setShow] = useState(true);
    const [image, setImage] = useState();
    const [logoText, setLogoText] = useState();
    const [socials, setSocials] = useState();
    const [sections, setSections] = useState();

    const goTo = (url) => {
        window.open(url, '_blank');
    }

    const getFontSize = (str) => {
        if (str.length > 30) return 18;
        if (str.length > 20) return 23;
        if (str.length > 15) return 33;
        if (str.length > 10) return 40;
        if (str.length >= 7) return 55;
        if (str.length < 7) return 70;
    }

    useEffect(() => {
        (async () => {
            let result = await axios.post(`${process.env.REACT_APP_SERVER_URL}/getCreateDataByDomainName`, {
                domain: `${domain}`
            });
            if (result.data.result) {
                console.log(`data::`, result.data.data)
                if (result.data.data.image != "") {
                    setImage(result.data.data.image);
                }
                if (result.data.data.logoText != "") {
                    setLogoText(JSON.parse(result.data.data.logoText));
                    console.log(JSON.parse(result.data.data.logoText))
                }
                if (result.data.data.socials != "") {
                    setSocials(JSON.parse(result.data.data.socials));
                }
                if (result.data.data.sections != "") {
                    setSections(JSON.parse(result.data.data.sections));
                    console.log(`kkk::`, JSON.parse(result.data.data.sections));
                }
            }
        })()
    }, [])

    const followUser = async () => {
        let result = await axios.post(`${process.env.REACT_APP_SERVER_URL}/followUser1`, {
            domain: `${domain}`,
            userId: searchParams.get('userId')
        });
        if (result.data.result) {
            setShow(true);
        }
    }

    return (
        <div className="w-full h-full bg-[#030303] relative">

            <div className="relative w-full">
                <img src={`${process.env.REACT_APP_STATIC_URL}/${image}`} alt="" className="aspect-7/8" />
                <div className="absolute top-0 w-full h-[70px] bg-gradient-to-b from-[#030303ff] to-[#03030300]"></div>
                <div className="absolute bottom-[-1px] w-full h-[150px] bg-gradient-to-b from-[#03030300] to-[#030303ff]"></div>
                {
                    logoText &&
                    logoText.type == 0 &&
                    <div className="w-full flex flex-row justify-center absolute bottom-[-30px]">
                        <span className="text-white" style={{ fontFamily: logoText.fontFamily + '-bold', fontSize: getFontSize(logoText.text) + 'px' }}>{logoText.text}</span>
                    </div>
                }
                {
                    logoText &&
                    logoText.type == 1 &&
                    <div className="w-full flex flex-row justify-center absolute bottom-[-30px]">
                        <img src={`${process.env.REACT_APP_STATIC_URL}/${logoText.uri}`} alt="" className="w-[300px] h-[50px]" />
                    </div>
                }
            </div>

            <div className="w-full flex flex-row items-center justify-center gap-x-[15px] mt-[35px]">
                {
                    socials && Object.entries(socials).map((item, key) => (
                        <img key={key} src={Assets[item[0]]} alt="" className="w-[35px] h-[35px]" />
                    ))
                }
            </div>

            {
                (isShow && searchParams.get('type') == 1) &&
                <div className="absolute top-[480px] w-full h-[200px] bg-[#333333] rounded-[20px] flex flex-col">
                    <div className="flex flex-row items-center justify-center relative py-[18px]">
                        <span className="text-[14px] text-white">Follow drake on dizee</span>
                        <img onClick={() => setShow(false)} src={closeButton} alt="" className="absolute right-[20px] w-[25px] h-[25px]" />
                    </div>
                    <div className="flex flex-row justify-center">
                        <img src={`${process.env.REACT_APP_STATIC_URL}/${image}`} alt="" className="rounded-full w-[60px] h-[60px]" />
                    </div>
                    <div className="flex flex-row justify-center py-[18px]">
                        <div onClick={followUser} className="w-[90px] h-[21px] flex flex-row items-center justify-center bg-white text-black text-[14px] rounded-[8px]">Follow</div>
                    </div>
                </div>
            }

            <div className="flex flex-col gap-y-[40px] pb-[30px] mt-[200px]">

                {/* music part */}
                {
                    sections && sections.music && sections.music.length > 0 &&
                    <MusicSection initMusic={sections.music} goTo={goTo} />
                }

                {/* event parts */}
                {
                    sections && sections.event && sections.event.length > 0 &&
                    <EventSection initEvent={sections.event} goTo={goTo} />
                }

                {/* product parts */}
                {
                    sections && sections.product && sections.product.length > 0 &&
                    <ProductSection initProducts={sections.product} goTo={goTo} />
                }

                {/* video part */}
                {
                    sections && sections.video && sections.video.length > 0 &&
                    <VideoSection initVideo={sections.video} goTo={goTo} />
                }


                {/* social feed part */}
                {
                    sections && sections.socialFeed && sections.socialFeed.length > 0 &&
                    <SocialFeedSection initSocialFeed={sections.socialFeed} goTo={goTo} />
                }

                {/* custom link part */}
                {
                    sections && sections.customLink && sections.customLink.length > 0 &&
                    <CustomLinkSection initDatas={sections.customLink} goTo={goTo} />
                }

            </div>

        </div>
    )
}

export default Domain;