import { useState, useEffect } from "react";

export default function CustomLinkSection(props) {

    let { initDatas, goTo } = props;
    const [datas, setDatas] = useState();

    useEffect(() => {
        if (initDatas) {
            setDatas(initDatas)
        }
    }, [initDatas])

    return (
        <div className="w-full flex flex-col gap-y-[10px]">
            {
                datas && datas.length > 0 && <span className="text-white text-[18px] leading-[22px] p-2">MORE</span>
            }

            {
                datas && datas.length >= 4 &&
                <div className="w-full flex flex-row justify-center">
                    <div className="grid grid-cols-4 gap-x-[6px]">
                        {
                            datas.slice(0, 4).map((item, key) => (
                                <img key={key} src={`${process.env.REACT_APP_STATIC_URL}/${item.image}`} alt="" className="w-[70px] h-[100px] rounded-[6px]" onClick={() => goTo(item.uri)} />
                            ))
                        }
                    </div>
                </div>
            }

            {
                datas && datas.length == 3 &&
                <div className="w-full flex flex-row justify-center">
                    <div className="grid grid-cols-3 gap-x-[10px]">
                        {
                            datas.map((item, key) => (
                                <img key={key} src={`${process.env.REACT_APP_STATIC_URL}/${item.image}`} alt="" className="w-[120px] h-[150px] rounded-[6px]" onClick={() => goTo(item.uri)} />
                            ))
                        }
                    </div>
                </div>

            }

            {
                datas && datas.length == 2 &&
                <div className="w-full flex flex-row justify-center">
                    <div className="grid grid-cols-2 gap-x-[15px]">
                        {
                            datas.slice(0, 4).map((item, key) => (
                                <img key={key} src={`${process.env.REACT_APP_STATIC_URL}/${item.image}`} alt="" className="w-[180px] h-[150px] rounded-[6px]" onClick={() => goTo(item.uri)} />
                            ))
                        }
                    </div>
                </div>

            }

            {
                datas && datas.length == 1 &&
                <div className="w-full flex flex-row justify-center">
                    <div className="grid grid-cols-1">
                        {
                            datas.slice(0, 4).map((item, key) => (
                                <img key={key} src={`${process.env.REACT_APP_STATIC_URL}/${item.image}`} alt="" className="w-[370px] h-[200px] rounded-[6px]" onClick={() => goTo(item.uri)} />
                            ))
                        }
                    </div>
                </div>
            }


        </div>
    )
}

