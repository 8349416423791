import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const ResetPassword = () => {

    const [params, setParams] = useSearchParams();
    const [token, setToken] = useState('');
    const [password, setPassword] = useState();
    const [password1, setPassword1] = useState();

    useEffect(() => {
        let token = params.get('token');
        setToken(token);
    }, [])

    const save = async () => {
        let result = await axios.post(`${process.env.REACT_APP_SERVER_URL}/auth/resetPassword`, {
            code: token,
            password: password
        })
        if (result.data.result) {
            alert('reset password success!!!');
        }
    }

    return (
        <div className="flex flex-col items-center bg-[#161313] w-full h-screen">
            <span className="text-[#FDFAFA] text-[20px] font-medium mt-[100px]">Create New Password</span>
            <div className="flex flex-col items-center gap-y-[10px] mt-[100px] w-[230px]">
                <div className="flex flex-row w-full h-[38px] border border-[#FDFAFA] rounded-[10px]">
                    <input type="password" placeholder="password" value={password} onChange={(evt) => setPassword(evt.target.value)}
                        className="w-full bg-transparent px-[15px] text-[#FDFAFA] text-[12px] outline-none placeholder:text-[#FDFAFA]" />
                </div>
                <div className="flex flex-row w-full h-[38px] border border-[#FDFAFA] rounded-[10px]">
                    <input type="password" placeholder="confirm password" value={password1} onChange={(evt) => setPassword1(evt.target.value)}
                        className="w-full bg-transparent px-[15px] text-[#FDFAFA] text-[12px] outline-none placeholder:text-[#FDFAFA]" />
                </div>
                <span className="text-[#696974] text-[11px] leading-[16px] px-[5px]">
                    Your password must be 8 characters or longer with atleast one symbol
                </span>
            </div>
            <div onClick={save}
                className="bg-[#FDFAFA] w-[230px] h-[38px] flex flex-row items-center justify-center rounded-xl text-[11px] font-medium mt-[80px]">Save</div>
        </div >
    )
}

export default ResetPassword;