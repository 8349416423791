const Assets = {
    'instagram': require('./assets/images/instagram1.png'),
    'facebook': require('./assets/images/facebook1.png'),
    'twitter': require('./assets/images/twitter1.png'),
    'youtube': require('./assets/images/youtube1.png'),
    'spotify': require('./assets/images/spotify1.png'),
    'tiktok': require('./assets/images/tiktok1.png'),
    'dizee': require('./assets/images/dizee.png'),
}

export default Assets;