import { useEffect, useState } from "react";

export default function ProductSection(props) {

    const { initProducts, goTo } = props;

    const [products, setProducts] = useState([]);
    useEffect(() => {
        if (initProducts) {
            setProducts(initProducts);
        }
    }, [initProducts])

    const formatString = (str, length, type = 0) => {
        if (type == 0) return str.slice(0, length) + '...';
        else return str.slice(0, length);
    }

    return (
        <div className="flex flex-col gap-5">

            <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row items-center">
                    <span className="text-[18px] leading-[22px] text-white p-2">STORE</span>
                </div>
            </div>

            {
                products.length == 1 &&
                <div className="relative">
                    {
                        <img className="w-[370px] h-[200px] rounded-[6px]"
                            src={products[0].type ? `${process.env.REACT_APP_STATIC_URL}/${products[0].image}` : products[0].image}></img>
                    }
                    <div className="flex flex-col items-end absolute right-[15px] top-[5px]">
                        <span className="text-[12px] font-medium">
                            {
                                products[0].title.length > 15 ? products[0].title.slice(0, 15) + '...' : products[0].title
                            }
                        </span>
                        <span className="text-[12px] font-medium">
                            ${
                                products[0].price
                            }
                        </span>
                    </div>
                </div>
            }

            {
                products.length == 2 &&
                <div className="w-full flex flex-row justify-center">
                    <div className="flex flex-row gap-2">
                        <div className="relative">
                            {
                                <img className="w-[182px] h-[200px] rounded-[6px]"
                                    src={products[0].type ? `${process.env.REACT_APP_STATIC_URL}/${products[0].image}` : products[0].image}></img>
                            }
                            <div className="flex flex-col items-end absolute right-[15px] top-[5px]">
                                <span className="text-[12px] font-medium">
                                    {
                                        products[0].title.length > 15 ? products[0].title.slice(0, 15) + '...' : products[0].title
                                    }
                                </span>
                                <span className="text-[12px] font-medium">
                                    ${
                                        products[0].price
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="relative">
                            {
                                <img className="w-[182px] h-[200px] rounded-[6px]"
                                    src={products[1].type ? `${process.env.REACT_APP_STATIC_URL}/${products[1].image}` : products[1].image}></img>
                            }
                            <div className="flex flex-col items-end absolute right-[15px] top-[5px]">
                                <span className="text-[12px] font-medium">
                                    {
                                        products[1].title.length > 15 ? products[1].title.slice(0, 15) + '...' : products[1].title
                                    }
                                </span>
                                <span className="text-[12px] font-medium">
                                    ${
                                        products[1].price
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                products.length == 3 &&
                <div className="w-full flex flex-row justify-center">
                    <div className="flex flex-row gap-2">
                        <div className="relative">
                            {
                                <img className="w-[120px] h-[150px] rounded-[6px]"
                                    src={products[0].type ? `${process.env.REACT_APP_STATIC_URL}/${products[0].image}` : products[0].image}></img>
                            }
                            <div className="flex flex-col items-end absolute right-[15px] top-[5px]">
                                <span className="text-[12px] font-medium">
                                    {
                                        products[0].title.length > 15 ? products[0].title.slice(0, 15) + '...' : products[0].title
                                    }
                                </span>
                                <span className="text-[12px] font-medium">
                                    ${
                                        products[0].price
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="relative">
                            {
                                <img className="w-[120px] h-[150px] rounded-[6px]"
                                    src={products[1].type ? `${process.env.REACT_APP_STATIC_URL}/${products[1].image}` : products[1].image}></img>
                            }
                            <div className="flex flex-col items-end absolute right-[15px] top-[5px]">
                                <span className="text-[12px] font-medium">
                                    {
                                        products[1].title.length > 15 ? products[1].title.slice(0, 15) + '...' : products[1].title
                                    }
                                </span>
                                <span className="text-[12px] font-medium">
                                    ${
                                        products[1].price
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="relative">
                            {
                                <img className="w-[120px] h-[150px] rounded-[6px]"
                                    src={products[2].type ? `${process.env.REACT_APP_STATIC_URL}/${products[2].image}` : products[2].image}></img>
                            }
                            <div className="flex flex-col items-end absolute right-[15px] top-[5px]">
                                <span className="text-[12px] font-medium">
                                    {
                                        products[2].title.length > 15 ? products[2].title.slice(0, 15) + '...' : products[2].title
                                    }
                                </span>
                                <span className="text-[12px] font-medium">
                                    ${
                                        products[2].price
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                products.length == 4 &&
                <div className="w-full flex flex-row justify-center">
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-row gap-2">
                            <div className="relative">
                                {
                                    <img className="w-[180px] h-[130px] rounded-[6px]"
                                        src={products[0].type ? `${process.env.REACT_APP_STATIC_URL}/${products[0].image}` : products[0].image}></img>
                                }
                                <div className="flex flex-col items-end absolute right-[15px] top-[5px]">
                                    <span className="text-[12px] font-medium">
                                        {
                                            products[0].title.length > 15 ? products[0].title.slice(0, 15) + '...' : products[0].title
                                        }
                                    </span>
                                    <span className="text-[12px] font-medium">
                                        ${
                                            products[0].price
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="relative">
                                {
                                    <img className="w-[180px] h-[130px] rounded-[6px]"
                                        src={products[1].type ? `${process.env.REACT_APP_STATIC_URL}/${products[1].image}` : products[1].image}></img>
                                }
                                <div className="flex flex-col items-end absolute right-[15px] top-[5px]">
                                    <span className="text-[12px] font-medium">
                                        {
                                            products[1].title.length > 15 ? products[1].title.slice(0, 15) + '...' : products[1].title
                                        }
                                    </span>
                                    <span className="text-[12px] font-medium">
                                        ${
                                            products[1].price
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row gap-2">
                            <div className="relative">
                                {
                                    <img className="w-[180px] h-[130px] rounded-[6px]"
                                        src={products[2].type ? `${process.env.REACT_APP_STATIC_URL}/${products[2].image}` : products[2].image}></img>
                                }
                                <div className="flex flex-col items-end absolute right-[15px] top-[5px]">
                                    <span className="text-[12px] font-medium">
                                        {
                                            products[2].title.length > 15 ? products[2].title.slice(0, 15) + '...' : products[2].title
                                        }
                                    </span>
                                    <span className="text-[12px] font-medium">
                                        ${
                                            products[2].price
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="relative">
                                {
                                    <img className="w-[180px] h-[130px] rounded-[6px]"
                                        src={products[3].type ? `${process.env.REACT_APP_STATIC_URL}/${products[3].image}` : products[3].image}></img>
                                }
                                <div className="flex flex-col items-end absolute right-[15px] top-[5px]">
                                    <span className="text-[12px] font-medium">
                                        {
                                            products[3].title.length > 15 ? products[3].title.slice(0, 15) + '...' : products[3].title
                                        }
                                    </span>
                                    <span className="text-[12px] font-medium">
                                        ${
                                            products[3].price
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                products.length >= 5 &&
                <div className="w-full flex flex-row justify-center">
                    <div className="flex flex-row gap-x-[5px]">
                        <div className="flex flex-col gap-y-[10px]">
                            <div className="flex flex-row gap-x-[10px]">
                                <div className="relative">
                                    <img className="w-[92px] h-[110px] rounded-[6px]"
                                        src={products[1].type ? `${process.env.REACT_APP_STATIC_URL}/${products[1].image}` : products[1].image} />
                                    <div className="flex flex-col items-start absolute top-[5px] left-[5px]">
                                        <span className="text-[9px] font-medium text-white">
                                            {
                                                formatString(products[1].title, 10)
                                            }
                                        </span>
                                        <span className="text-[9px] font-medium text-white">
                                            ${
                                                products[1].price
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="relative">
                                    <img className="w-[92px] h-[110px] rounded-[6px]"
                                        src={products[2].type ? `${process.env.REACT_APP_STATIC_URL}/${products[2].image}` : products[2].image} />
                                    <div className="flex flex-col items-start absolute top-[5px] left-[5px]">
                                        <span className="text-[9px] font-medium text-white">
                                            {
                                                formatString(products[2].title, 10)
                                            }
                                        </span>
                                        <span className="text-[9px] font-medium text-white">
                                            ${
                                                products[2].price
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row gap-x-[10px]">
                                <div className="relative">
                                    <img className="w-[92px] h-[110px] rounded-[6px]"
                                        src={products[3].type ? `${process.env.REACT_APP_STATIC_URL}/${products[3].image}` : products[3].image} />
                                    <div className="flex flex-col items-start absolute top-[5px] left-[5px]">
                                        <span className="text-[9px] font-medium text-white">
                                            {
                                                formatString(products[3].title, 10)
                                            }
                                        </span>
                                        <span className="text-[9px] font-medium text-white">
                                            ${
                                                products[3].price
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="relative">
                                    <img className="w-[92px] h-[110px] rounded-[6px]"
                                        src={products[4].type ? `${process.env.REACT_APP_STATIC_URL}/${products[4].image}` : products[4].image} />
                                    <div className="flex flex-col items-start absolute top-[5px] left-[5px]">
                                        <span className="text-[9px] font-medium text-white">
                                            {
                                                formatString(products[4].title, 10)
                                            }
                                        </span>
                                        <span className="text-[9px] font-medium text-white">
                                            ${
                                                products[4].price
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="relative">
                            {
                                <img className="w-[180px] h-[230px] rounded-[6px]"
                                    src={products[0].type ? `${process.env.REACT_APP_STATIC_URL}/${products[0].image}` : products[0].image}></img>
                            }
                            <div className="flex flex-col items-end absolute right-[15px] top-[5px]">
                                <span className="text-[11px] font-medium">
                                    {
                                        formatString(products[0].title, 18, 1)
                                    }
                                </span>
                                <span className="text-[11px] font-medium">
                                    ${
                                        products[0].price
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

