import { useState, useEffect, useRef } from "react";

export default function VideoSection(props) {

    let { initVideo } = props;
    const [videoList, setVideoList] = useState();
    // const curVideo = useRef(null);
    const [curShowVideo, setCurVideo] = useState(null);

    useEffect(() => {
        if (initVideo && initVideo.length > 0) {
            setVideoList(initVideo);
            setCurVideo(initVideo[0]);
        }
    }, [initVideo])

    return (

        <div className="flex flex-col gap-y-[10px]">
            {
                videoList && videoList.length > 0 && <span className="text-white text-[18px] leading-[22px] p-2">VIDEO</span>
            }
            <div className="flex flex-col items-center rounded-[6px] bg-[#161313] mx-[10px] overflow-hidden">

                <div className="w-full flex flex-row items-center justify-center relative">

                    {
                        curShowVideo && <video
                            // src={item.uri}
                            className="w-full h-[230px] object-fill"
                            src={'http://localhost:8080/public/section_65e0b2b325df29ff8d41778b_1712230819640.mp4'}
                            type="video/mp4"
                            controls
                        />
                    }

                </div>

                <div className="flex flex-row p-2 justify-center items-center gap-x-2 overflow-scroll ">

                    {
                        videoList && videoList.length > 1 &&
                        videoList.map((item, key) => (
                            <div key={key} className="w-[70px] flex flex-row items-center justify-center relative">
                                <video
                                    className="w-full h-[50px] object-fill"
                                    // src={item.uri}
                                    src={'http://localhost:8080/public/section_65e0b2b325df29ff8d41778b_1712230819640.mp4'}
                                    type="video/mp4"
                                // controls
                                />
                            </div>
                        ))
                    }

                </div>

            </div>
        </div>

    )
}