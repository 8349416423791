import { useEffect, useState } from "react";

export default function EventSection(props) {

    let { initEvent, goTo } = props;
    const [event, setEvent] = useState();

    useEffect(() => {
        if (initEvent) {
            setEvent(initEvent)
        }
    }, [initEvent])

    return (
        <div className="w-full flex flex-col gap-y-[10px] mt-[15px]">
            {
                event && event.length > 0 && <span className="text-white text-[18px] leading-[22px] p-2">LIVE</span>
            }

            {
                event && event.length >= 4 &&
                <div className="w-full flex flex-row justify-center">
                    <div className="grid grid-cols-4 gap-x-[10px]">
                        {
                            event.slice(0, 4).map((item, key) => (
                                Number(item.type) ?
                                    <img key={key} src={`${process.env.REACT_APP_STATIC_URL}/${item.image}`} alt="" className="w-[85px] h-[120px] rounded-[6px]" onClick={() => goTo(item.uri)} /> :
                                    <img key={key} src={`${item.image}`} alt="" className="w-[85px] h-[120px] rounded-[6px]" onClick={() => goTo(item.uri)} />
                            ))
                        }
                    </div>
                </div>
            }

            {
                event && event.length == 3 &&
                <div className="w-full flex flex-row justify-center">
                    <div className="grid grid-cols-3 gap-x-[10px]">
                        {
                            event.map((item, key) => (
                                Number(item.type) ?
                                    <img key={key} src={`${process.env.REACT_APP_STATIC_URL}/${item.image}`} alt="" className="w-[120px] h-[150px] rounded-[6px]" onClick={() => goTo(item.uri)} /> :
                                    <img key={key} src={`${item.image}`} alt="" className="w-[120px] h-[150px] rounded-[6px]" onClick={() => goTo(item.uri)} />
                            ))
                        }
                    </div>
                </div>

            }

            {
                event && event.length == 2 &&
                <div className="w-full flex flex-row justify-center">
                    <div className="grid grid-cols-2 gap-x-[15px]">
                        {
                            event.slice(0, 4).map((item, key) => (
                                Number(item.type) ?
                                    <img key={key} src={`${process.env.REACT_APP_STATIC_URL}/${item.image}`} alt="" className="w-[180px] h-[150px] rounded-[6px]" onClick={() => goTo(item.uri)} /> :
                                    <img key={key} src={`${item.image}`} alt="" className="w-[180px] h-[150px] rounded-[6px]" onClick={() => goTo(item.uri)} />
                            ))
                        }
                    </div>
                </div>

            }

            {
                event && event.length == 1 &&
                <div className="w-full flex flex-row justify-center">
                    <div className="grid grid-cols-1">
                        {
                            event.slice(0, 4).map((item, key) => (
                                Number(item.type) ?
                                    <img key={key} src={`${process.env.REACT_APP_STATIC_URL}/${item.image}`} alt="" className="w-[370px] h-[200px] rounded-[6px]" onClick={() => goTo(item.uri)} /> :
                                    <img key={key} src={`${item.image}`} alt="" className="w-[370px] h-[200px] rounded-[6px]" onClick={() => goTo(item.uri)} />
                            ))
                        }
                    </div>
                </div>
            }


        </div>
    )
}